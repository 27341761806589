import ComponentBase from "@components/ComponentBase";
import { ComponentElem } from "@components/interfaces";
import Tools from "../../ts/Tools";
import Pager from "../ui/Pager";
import ApiProxy from "../../ts/ApiProxy";
import WorkThumb from "../ui/WorkThumb";
import Utility from "../../ts/sframe/Utility";
import gsap from "gsap";
import ViewportRoot from "../../ts/sframe/ViewportRoot";
import Geom from "../../ts/sframe/Geom";
import GS from "../../ts/GlobalSetting";

export type WorkThumbData = {

  index: number

  id: string

  image: {
    pc: string,
    mobile: string,
  },

  hashtag_list: string[],

  title: string,
  description: string
}

const SETTING =
{
  pc: {
    maxColSize: 3,
    minColSize: 2,
    maxRowSize: 2,
    minRowSize: 2,
    containerWidth: 1675,
    bleed: 1834 - 1675
  },
  mobile: {
    maxColSize: 1,
    minColSize: 1,
    maxRowSize: 6,
    minRowSize: 1,
    containerWidth: 640,
    bleed: 0
  }
}

export default class Work extends ComponentBase
{
  private _dataList: WorkThumbData[];

  private _thumbList: WorkThumb[];

  private _pager: Pager;

  private _colSize: number = 0;
  private _rowSize: number = 2;

  private _updatePageSize: Function;

  private _mainTl: gsap.core.Timeline;

  constructor(elem: ComponentElem)
  {
    super(elem);
    
    this._dataList = ApiProxy.getStoredData("index/get-work-list").list;
    for(let i=0;i<this._dataList.length;i++)
    {
      this._dataList[i].index = i;
    }

    this._setupResize();
    this._setupContents();
    this._setupAnimate();

    // ViewportRoot.update();
  }


  private _setupResize()
  {
    if (Utility.urlParams.test === "animation" || Utility.urlParams.test === "layout")
    {
      this.$root.attr("test-for-animation", "true");
    }

    if (Utility.urlParams.test === "animation") return;


    let $marker = this.$root.find("#work-marker"),
      $content = this.$root.find(">.content");

    ViewportRoot.emitter.on("updated", (vp) =>
    {

      let bound = Geom.caculateContain(vp.width - GS.getNavSize().width, vp.height - GS.getNavSize().height, 1836, 930);
      // console.log(bound.ratio);
      let ratio = (vp.width - GS.getNavSize().width) / (1836);
      ratio = Math.min(1, ratio);

      if (vp.index === 0)
      {
        this.$root.css({
          "font-size": ""
        });
        $marker.css("bottom", "");
      }
      else
      {
        this.$root.css({
          "font-size": (GS.EM_TO_PX * ratio) + "px"
        });

        $marker.css("bottom", Math.max(1200, vp.height));
      }

      let p = vp.index === 0 ? SETTING.mobile : SETTING.pc;

      const thumbWidth = 545,
        gapX = 20,
        maxColSize = p.maxColSize,
        minColSize = p.minColSize,
        rawThumbContainerWidth = p.containerWidth,
        newRowSize = p.maxRowSize;

      let newThumbContainerWidth = rawThumbContainerWidth * ratio,
        newColSize = Math.ceil((newThumbContainerWidth - gapX * ratio) / (thumbWidth + gapX * ratio));

      newColSize = Math.min(maxColSize, newColSize);
      newColSize = Math.max(minColSize, newColSize);

      // console.log(newColSize);

      // if(vp.index === 0) this._mainTl.scrollTrigger.endAnimation();
      

      this._updatePageSize(newColSize, newRowSize);
    });
  }

  private _setupAnimate()
  {
    if (Utility.urlParams.test === "layout") return;

    let q = gsap.utils.selector(this.$root[0]);

    let trigger = {
      trigger: this.$root[0],
      start: "top center",
      end: "bottom 100%",
      scrub: ViewportRoot.index === 0? false: 1
    };



    if (Utility.urlParams.test === "animation") trigger = undefined;

    let tl, tl1, tl2, mainTl;

    tl = tl1 = gsap.timeline();

    let chars: { [key: string]: HTMLElement[] } =
    {
      title: Tools.splitText(this.$root.find(".top-part .title")[0]),
      detail: Tools.splitText(this.$root.find(".top-part .detail")[0]),
    };

    tl.set(chars.title, { opacity: 0, y: 100 });
    tl.set(chars.detail, { opacity: 0, transformOrigin: "center bottom", scaleY: 0, y: 20 });

    
    
    ViewportRoot.index === 0 ?
      tl.set(q(".thumb-container>.wrapper"), { rotateX: -30, y: 500, opacity: 0 }) :
      tl.set(q(".thumb-container>.wrapper"), { rotateX: -60, y: 500, opacity: 0 });

    tl.set(q(".pager"), { opacity: 0 });
    // tl.set(q(".gap-line"), {height: 0});

    tl.addLabel("start", .01);

    tl.to(chars.title, { duration: .4, opacity: 1, y: 0, stagger: .05, ease: "power1.out" }, "start");
    tl.from(q(".gap-line"), { duration: .4, height: "0%" }, "-=.3");
    tl.to(chars.detail, { duration: .4, scaleY: 1, opacity: 1, y: 0, stagger: .02, ease: "back.out" }, "-=.3");
    tl.addLabel("thumb-in", "-=.5");
    tl.to(q(".thumb-container>.wrapper"), { duration: .3, opacity: 1 }, "thumb-in");
    tl.to(q(".thumb-container>.wrapper"), { duration: 1, rotateX: 0, y: 0 }, "thumb-in");

    tl.to(q(".pager"), { duration: .3, opacity: 1 }, "-=.3");


    // 混合動畫
    mainTl = gsap.timeline({ scrollTrigger: trigger });
    // mainTl.set(q(".graphic-part"), { opacity: 0 });
    mainTl.addLabel("start", .01);
    mainTl.add(tl1, 0);
    // mainTl.addLabel("tl2-in", "-=0.5");

    this._mainTl = mainTl;

    mainTl.add(() =>
    {
      // console.log("end");
    }, "+=.5");

    mainTl.seek("start").pause();

    // 測試用
    if (Utility.urlParams.test === "animation") Tools.setTimelineTrigger(window, mainTl, "start");
  }

  private _setupContents()
  {

    let self = this,
      isLocking = false,
      currentIndex = -1,
      pageSize: number,
      numItems = this._dataList.length,
      cbUnlocking: Function = undefined,
      $thumbContainer = this.$root.find(".thumb-container");

    this._pager = Tools.seekComponentByName("pager", this.$root);
    this._pager.setUseNumber(false).setMaxSize(100000).setManualChange(true);
    // this._pager.reset(pageSize, numItems);
    this._pager.emiiter.on("change", (pageIndex: number) =>
    {
      // console.log(`new page index: ${pageIndex}`);
      if (isLocking) return;
      toPage(pageIndex);
      if(ViewportRoot.index === 0) Tools.scrollToElement("work-marker");
    });

    let $container = this.$root.find(".thumb-container>.wrapper").empty();

    // toPage(0, true);

    self._updatePageSize = (newColSize: number, newRowSize: number) =>
    {
      if (newColSize === self._colSize && newRowSize === self._rowSize) return;

      (isLocking) ? cbUnlocking = execute : execute();

      function execute()
      {
        self._colSize = newColSize;
        self._rowSize = newRowSize;

        pageSize = self._colSize * self._rowSize;

        self._pager.reset(pageSize, numItems);
        toPage(0, true);
      }


    };

    function toPage(index: number, instantPlay: boolean = false)
    {
      if (isLocking) return; isLocking = true;

      currentIndex = index;
      self._pager.toIndex(currentIndex);
      update(instantPlay);
    }

    function update(instantPlay: boolean = false) 
    {
      contentOut(() =>
      {
        $container.empty();
        self._thumbList = [];

        let startIndex = currentIndex * pageSize,
          endIndex = Math.min(startIndex + pageSize, numItems);

        // console.log(`startIndex: ${startIndex}, endIndex: ${endIndex}`);

        for (let i = startIndex; i < endIndex; i++)
        {
          let workData = self._dataList[i];
          workData.index = i;
          
          let workThumb = WorkThumb.generate(workData);
          workThumb.$root.attr("pc-col-size", self._colSize);

          workThumb.$root.on("click", (event:JQuery.ClickEvent)=>
          {
            event.preventDefault();
            if(isLocking) return;
            workThumb.toMyDetail();
          });

          gsap.set(workThumb.$root, { opacity: 0, rotateX: 45, rotateZ: -15, x: -200, y: -100 });
          $container.append(workThumb.$root);
          self._thumbList.push(workThumb);
        }

        contentIn(() =>
        {
          // console.log($container.height());
          
          $thumbContainer.css("min-height", $container.height());

          isLocking = false;
          if (cbUnlocking)
          {
            let func = cbUnlocking;
            cbUnlocking = undefined;
            func.call(null);
          }
        }, instantPlay);

      }, instantPlay);


    }

    function contentOut(cb: Function, instantPlay: boolean = false)
    {
      let duration = instantPlay ? 0 : .5,
        stagger = instantPlay ? 0 : .05;

      let tl = gsap.timeline(),
        $thumbs = self.$root.find(`[component="work-thumb"]`);

      // console.log($thumbs);
      
      if($thumbs.length > 0)  tl.to($thumbs, { duration: duration, opacity: 0, stagger: stagger, rotateX: 45, rotateZ: 15, x: 200, y: -100, ease: "power1.in" });

      tl.add(() =>
      {
        cb.call(null);
      });
    }

    function contentIn(cb: Function, instantPlay: boolean = false) 
    {
      let duration = instantPlay ? 0 : .5,
        stagger = instantPlay ? 0 : .05;

      let tl = gsap.timeline(),
        $thumbs = self.$root.find(`[component="work-thumb"]`);

      tl.to($thumbs, { duration: duration, opacity: 1, rotateX: 0, rotateZ: 0, stagger: stagger, x: 0, y: 0 });

      tl.add(() =>
      {
        cb.call(null);
      });
    }
  }
}